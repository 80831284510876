/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$MES-theme-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
$MES-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$MES-theme-warn: mat.define-palette(mat.$red-palette);

$MES-theme: mat.define-light-theme($MES-theme-primary, $MES-theme-accent, $MES-theme-warn);

@include mat.all-legacy-component-themes($MES-theme);

@import "src/component-styles";
@import "src/styles_flex.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@mixin md-icon-size($size: 36px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.md-drppicker .btn {
  background-color: #0277BD !important;
}

.md-drppicker td.active{
  background-color: #0277BD !important;
}

.dateRangeBut{
  max-width: 300px;
  margin: 5px;
}

.banner{
  background: #0277BD;
  color: white;
}

table {
  @include gea-table-theme;
}

.sidenav-container {
  height: 100%;
}

.sidenav {
  width: 200px;
}

.sidenav .mat-toolbar {
  background: inherit;
}

.mat-toolbar.mat-primary {
  position: sticky;
  top: 0;
  z-index: 1;
}

.flex {
  flex: 1 1 auto;
}

.mat-dialog-actions {
  // ...
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }
}

.cards {
  max-width: 100%;
  margin: 1%;
  padding-left: 5px;
}

.card-complete {
  max-width: 100%;
  margin: 1%;
  background-color: yellowgreen;
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }  
}

.card-did-not-complete-issue {
  max-width: 100%;
  margin: 1%;
  background-color: aqua;
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }  
}

.card-complete-issue {
  max-width: 100%;
  margin: 1%;
  background-color: rgb(236, 38, 38);
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }  
}

.card-section-header {
  max-width: 100%;
  margin: 1%;
  background-color:lightgray;
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }  
}

.card-dialog {
  min-width: 225px;
  max-width: 500px;
  width: 100%;
}

.eam-card-dialog {
  min-width: 225px;
  max-width: 1000px;
  width: 100%;
}

.card-dialog {
  min-width: 225px;
  max-width: 500px;
  width: 100%;
}
.readonly-card-dialog{
  color: gray;
  min-width: 225px;
  max-width: 500px;
  width: 100%;
}

.hourSelect {
  margin-left: 5%;
}

.tasklist-header {
  font-size: 16px;
}


.task-complete {
  background-color: yellowgreen;
}


.task-complete-issue {
  background-color: rgb(236, 38, 38);
}

.task-did-not-complete-issue {
  background-color: aqua;
}

.make-gold {
  background-color: gold
}

.card-section {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  height: 60px;
}

.card-margin {
  margin: 0 10px;
}

.example-spacer {
  flex: 1 1 auto;
}

.homeDashButtons{
  margin-left: 10px;
}

.required-label{
  color: #1976D2;
}

.CellSpacingMedium {
  margin: 5px 5px 5px 5px;
}

.HeadingSmall {
  font-size: 12px;
  margin: auto;
}